import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "react-vertical-timeline-component/style.min.css";
import "../../App.css";

import { Button, Container, ProgressBar } from "react-bootstrap";

function EchoSystem() {
  const echo1 = require("../../assets/images/echo1.png");
  const echo2 = require("../../assets/images/echo2.png");
  const echo3 = require("../../assets/images/echo3.png");
  const echo4 = require("../../assets/images/echo4.png");
  const echo5 = require("../../assets/images/echo5.png");
  const echo6 = require("../../assets/images/echo6.png");
  const echo7 = require("../../assets/images/echo7.png");
  const echo8 = require("../../assets/images/echo8.png");
  return (
    <React.Fragment>
      <Container id="echo">
        <div className="row">
          <div className="col-xs-12">
            <h1
              className="poppinsBold White-Color-Text mt-3"
              style={{ textAlign: "center" }}
            >
              ECHOSYSTEM
            </h1>
          </div>
          <div className="col-md-4 col-xs-12 mt-3">
            <div
              className="card"
              style={{
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#16181C",
              }}
            >
              <img src={echo1} style={{ height: "20vh", width: "20vh" }} />{" "}
              <h1
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  marginTop: "10px",
                }}
                className="poppinsBold Yellow-Color-Text"
              >
                1. Customer Make Order
              </h1>
              <p
                style={{
                  textAlign: "left",
                  fontSize: "10px",
                  padding: "10px",
                }}
                className="poppinsRegular White-Color-Text"
              >
                Customer will be able to order their desired product from their
                desired restaurant from customer app.
              </p>
            </div>
          </div>

          <div className="col-md-4 col-xs-12 mt-3">
            <div
              className="card "
              style={{
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#16181C",
              }}
            >
              <img src={echo2} style={{ height: "20vh", width: "20vh" }} />{" "}
              <h1
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  marginTop: "10px",
                }}
                className="poppinsBold Yellow-Color-Text"
              >
                2. Customer Pay Restaurants
              </h1>
              <p
                style={{
                  textAlign: "left",
                  fontSize: "10px",
                  padding: "10px",
                }}
                className="poppinsRegular White-Color-Text"
              >
                Customers will pay order amount to restaurants using cashbackit
                platform.
              </p>
            </div>
          </div>

          <div className="col-md-4 col-xs-12 mt-3">
            <div
              className="card "
              style={{
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#16181C",
              }}
            >
              <img src={echo3} style={{ height: "20vh", width: "20vh" }} />{" "}
              <h1
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  marginTop: "10px",
                }}
                className="poppinsBold Yellow-Color-Text"
              >
                3. Restaurant Process Order
              </h1>
              <p
                style={{
                  textAlign: "left",
                  fontSize: "10px",
                  padding: "10px",
                }}
                className="poppinsRegular White-Color-Text"
              >
                Restaurants will prepare the food items requested in order.
              </p>
            </div>
          </div>

          <div className="col-md-4 col-xs-12 mt-3">
            <div
              className="card "
              style={{
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#16181C",
              }}
            >
              <img src={echo4} style={{ height: "20vh", width: "20vh" }} />{" "}
              <h1
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  marginTop: "10px",
                }}
                className="poppinsBold Yellow-Color-Text"
              >
                4. Drivers Deliver Order
              </h1>
              <p
                style={{
                  textAlign: "left",
                  fontSize: "10px",
                  padding: "10px",
                }}
                className="poppinsRegular White-Color-Text"
              >
                Drivers will earn delivery charges using cashbackit driver app
                and deliver order to customers.
              </p>
            </div>
          </div>

          <div className="col-md-4 col-xs-12 mt-3">
            <div
              className="card "
              style={{
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#16181C",
              }}
            >
              <img src={echo5} style={{ height: "20vh", width: "20vh" }} />{" "}
              <h1
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  marginTop: "10px",
                }}
                className="poppinsBold Yellow-Color-Text"
              >
                5. Restaurant Give CASHBACK
              </h1>
              <p
                style={{
                  textAlign: "left",
                  fontSize: "10px",
                  padding: "10px",
                }}
                className="poppinsRegular White-Color-Text"
              >
                After successful delivery of each order, restaurants will give
                cashback to customers through cashbackit app.
              </p>
            </div>
          </div>

          <div className="col-md-4 col-xs-12 mt-3">
            <div
              className="card "
              style={{
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#16181C",
              }}
            >
              <img src={echo6} style={{ height: "20vh", width: "20vh" }} />{" "}
              <h1
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  marginTop: "10px",
                }}
                className="poppinsBold Yellow-Color-Text"
              >
                6. CONVERSION TO CBT
              </h1>
              <p
                style={{
                  textAlign: "left",
                  fontSize: "10px",
                  padding: "10px",
                }}
                className="poppinsRegular White-Color-Text"
              >
                Cashbackit app will automatically convert cashback into CBT to
                give to customer.
              </p>
            </div>
          </div>

          <div className="col-md-4 col-xs-12 mt-3">
            <div
              className="card "
              style={{
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#16181C",
              }}
            >
              <img src={echo7} style={{ height: "20vh", width: "20vh" }} />{" "}
              <h1
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  marginTop: "10px",
                }}
                className="poppinsBold Yellow-Color-Text"
              >
                7. CONTINUOUS ADD UP INTO LIQUIDITY
              </h1>
              <p
                style={{
                  textAlign: "left",
                  fontSize: "10px",
                  padding: "10px",
                }}
                className="poppinsRegular White-Color-Text"
              >
                Continuous conversion of cashback into cbt will Increase the
                buying side of CBT will add more money into liquidity.
              </p>
            </div>
          </div>

          <div className="col-md-4 col-xs-12 mt-3">
            <div
              className="card "
              style={{
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#16181C",
              }}
            >
              <img src={echo8} style={{ height: "20vh", width: "20vh" }} />{" "}
              <h1
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  marginTop: "10px",
                }}
                className="poppinsBold Yellow-Color-Text"
              >
                8. CBT PRICE GO UP
              </h1>
              <p
                style={{
                  textAlign: "left",
                  fontSize: "10px",
                  padding: "10px",
                }}
                className="poppinsRegular White-Color-Text"
              >
                Due to increament of liquidity, CBT price will rise day by day.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default EchoSystem;
