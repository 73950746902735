import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "react-vertical-timeline-component/style.min.css";
import "../../App.css";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { FaSuitcase } from "react-icons/fa";

import { Button, Container, ProgressBar } from "react-bootstrap";

function RoadMap() {
  const missionImage = require("../../assets/images/mission.png");

  return (
    <React.Fragment>
      <Container id="roadmap" className="pt-5">
        <p
          className="poppinsBold White-Color-Text"
          style={{ textAlign: "center" }}
        >
          Company Roadmap
        </p>
        <div className="row p-5">
          <div className="col-md-12 col-xs-12">
            <VerticalTimeline>
              <VerticalTimelineElement
                contentStyle={{
                  background: "#16181C",
                  color: "#fff",
                }}
                contentArrowStyle={{
                  borderRight: "7px solid #16181C ",
                }}
                iconStyle={{ background: "#16181C", color: "#fff" }}
                icon={<FaSuitcase />}
              >
                <h3 className="poppinsExtraBold" style={{ fontSize: "14px" }}>
                  Week 1-2
                </h3>
                <h4 className="poppinsSemiBold" style={{ fontSize: "12px" }}>
                  Post-Presale Evaluation and Token Distribution
                </h4>
                <p className="poppinsRegular" style={{ fontSize: "10px" }}>
                  1. Evaluate the results of the presale, including the total
                  funds raised and the number of investors.
                  <br /> 2. Distribute Cashbackit tokens to presale
                  participants.
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                contentStyle={{
                  background: "#16181C",
                  color: "#fff",
                }}
                contentArrowStyle={{
                  borderRight: "7px solid #16181C ",
                }}
                iconStyle={{ background: "#16181C", color: "#fff" }}
                icon={<FaSuitcase />}
              >
                <h3 className="poppinsExtraBold" style={{ fontSize: "14px" }}>
                  Week 3-4
                </h3>
                <h4 className="poppinsSemiBold" style={{ fontSize: "12px" }}>
                  Development Kickoff
                </h4>
                <p className="poppinsRegular" style={{ fontSize: "10px" }}>
                  1. Assemble the development team and allocate roles and
                  responsibilities.
                  <br />
                  2. Start the development of the customer app, focusing on
                  user-friendly interfaces and seamless cashback integration.
                  <br />
                  3. Initiate the development of the driver app, ensuring
                  efficient order management and delivery tracking.
                  <br />
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                contentStyle={{
                  background: "#16181C",
                  color: "#fff",
                }}
                contentArrowStyle={{
                  borderRight: "7px solid #16181C ",
                }}
                iconStyle={{ background: "#16181C", color: "#fff" }}
                icon={<FaSuitcase />}
              >
                <h3 className="poppinsExtraBold" style={{ fontSize: "14px" }}>
                  Week 5-6
                </h3>
                <h4 className="poppinsSemiBold" style={{ fontSize: "12px" }}>
                  Business App and Admin Panel Development
                </h4>
                <p className="poppinsRegular" style={{ fontSize: "10px" }}>
                  1. Commence the development of the business app, catering to
                  restaurant partners, enabling them to manage orders and
                  promotions.
                  <br /> 2. Start the development of the business admin panel,
                  providing comprehensive tools for overseeing the entire
                  system's operations.
                  <br /> 3. Design the super admin panel, which will have a
                  holistic view of the entire ecosystem and control over
                  system-wide settings.
                  <br />
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                contentStyle={{
                  background: "#16181C",
                  color: "#fff",
                }}
                contentArrowStyle={{
                  borderRight: "7px solid #16181C ",
                }}
                iconStyle={{ background: "#16181C", color: "#fff" }}
                icon={<FaSuitcase />}
              >
                <h3 className="poppinsExtraBold" style={{ fontSize: "14px" }}>
                  Week 7
                </h3>
                <h4 className="poppinsSemiBold" style={{ fontSize: "12px" }}>
                  Integration and Testing
                </h4>
                <p className="poppinsRegular" style={{ fontSize: "10px" }}>
                  1. Integrate the various components of the Cashbackit
                  ecosystem to ensure seamless communication and functionality.
                  <br />
                  2. Conduct rigorous testing of the customer app, driver app,
                  business app, and admin panels to identify and resolve any
                  bugs or issues.
                  <br />
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                contentStyle={{
                  background: "#16181C",
                  color: "#fff",
                }}
                contentArrowStyle={{
                  borderRight: "7px solid #16181C ",
                }}
                iconStyle={{ background: "#16181C", color: "#fff" }}
                icon={<FaSuitcase />}
              >
                <h3 className="poppinsExtraBold" style={{ fontSize: "14px" }}>
                  Week 8
                </h3>
                <h4 className="poppinsSemiBold" style={{ fontSize: "12px" }}>
                  Soft Launch and Marketing
                </h4>
                <p className="poppinsRegular" style={{ fontSize: "10px" }}>
                  1. Soft launch the Cashbackit platform in a limited
                  geographical area to gather user feedback and fine-tune the
                  system.
                  <br />
                  2. Implement an aggressive marketing strategy to promote the
                  platform, focusing on both customers and potential restaurant
                  partners.
                  <br />
                  3. Prepare for the official launch by addressing any feedback
                  from the soft launch and making final adjustments.
                  <br />
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                contentStyle={{
                  background: "#16181C",
                  color: "#fff",
                }}
                contentArrowStyle={{
                  borderRight: "7px solid #16181C ",
                }}
                iconStyle={{ background: "#16181C", color: "#fff" }}
                icon={<FaSuitcase />}
              >
                <h3 className="poppinsExtraBold" style={{ fontSize: "14px" }}>
                  Week 9
                </h3>
                <h4 className="poppinsSemiBold" style={{ fontSize: "12px" }}>
                  Official Launch and Ongoing Improvements
                </h4>
                <p className="poppinsRegular" style={{ fontSize: "10px" }}>
                  1. Officially launch Cashbackit to the public, emphasizing its
                  unique features and benefits.
                  <br />
                  2. Continue to monitor user feedback and address any emerging
                  issues promptly.
                  <br />
                  3. Implement planned updates and improvements based on user
                  experience and market demands.
                  <br />
                </p>
              </VerticalTimelineElement>
            </VerticalTimeline>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default RoadMap;
