import React from "react";
import Lottie from "lottie-react";
import { Card, Col, Row } from "react-bootstrap";
import MasterLayout from "../master/MasterLayout";
import loadingDots from "../../../assets/animation/loadingdots.json";

const Buyaleit = () => {
  return (
    <MasterLayout>
      <Row className="mt-5">
        <Col md={12} sm={12} xs={12}>
          <h3 className="poppinsBold Yellow-Color-Text mb-5">Purchase ALEIT</h3>

          <Card
            className="poppinsRegular Yellow-Color-Text p-2 mt-2 text-center"
            style={{ backgroundColor: "#16181C" }}
          >
            <Row>
              <Col xs={12}>
                <Row>
                  <Col xs={5}>
                    <Lottie
                      animationData={loadingDots}
                      loop={true}
                      style={{ height: 100, width: 100,position:"relative",top:"50%",left:"85%" }}
                    />
                  </Col>
                  <Col xs={2}>
                    <img
                      src={require("../../../assets/images/logo512.png")}
                      className="img-fluid"
                    />
                  </Col>
                  <Col xs={5}>
                    <Lottie
                      animationData={loadingDots}
                      loop={true}
                      style={{ height: 100, width: 100,position:"relative",top:"50%", right: "10%" }}
                    />
                  </Col>
                </Row>

                <h1
                  className="Yellow-Color-Text"
                  style={{ textDecoration: "none", marginTop: 25 }}
                >
                  Coming Soon
                </h1>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </MasterLayout>
  );
};

export default Buyaleit;
