import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FaArrowRight, FaEllipsisH } from "react-icons/fa";
const LiquidityChart = () => {
  const uptrend = require("../../assets/images/uptrend.png");

  return (
    <Row>
      <Col md={4} xs={4}>
        <Card
          className="p-3 mb-5 shadow-lg"
          style={{ backgroundColor: "#16181C",marginTop:"-30px" }}
        >
          <h5 className="poppinsBold Yellow-Color-Text">150k</h5>
          <p
            className="poppinsRegular White-Color-Text"
            style={{ fontSize: "12px" }}
          >
            Avg Daily Orders
          </p>
        </Card>
      </Col>
      <Col md={4} xs={4}>
        <Card
          className="p-3 mb-5 shadow-lg"
          style={{ backgroundColor: "#16181C",marginTop:"-30px" }}
        >
          <h5 className="poppinsBold Yellow-Color-Text" style={{}}>
            15 USDT
          </h5>
          <p
            className="poppinsRegular White-Color-Text"
            style={{ fontSize: "12px" }}
          >
            Avg order price
          </p>
        </Card>
      </Col>
      <Col md={4} xs={4}>
        <Card
          className="p-3 mb-5 shadow-lg"
          style={{ backgroundColor: "#16181C",marginTop:"-30px" }}
        >
          <h5 className="poppinsBold Yellow-Color-Text" style={{}}>
            60k
          </h5>
          <p
            className="poppinsRegular White-Color-Text"
            style={{ fontSize: "12px" }}
          >
            Targeted Orders
          </p>
        </Card>
      </Col>
      <Col md={4} xs={4}>
        <Card
          className="p-3 mb-5 shadow-lg"
          style={{ backgroundColor: "#16181C",marginTop:"-30px" }}
        >
          <h5 className="poppinsBold Yellow-Color-Text" style={{}}>
            5%
          </h5>
          <p
            className="poppinsRegular White-Color-Text"
            style={{ fontSize: "10px" }}
          >
            Cashback
            (0.75 USDT)
          </p>
        </Card>
      </Col>
      <Col md={4} xs={4}>
        <Card
          className="p-3 mb-5 shadow-lg"
          style={{ backgroundColor: "#16181C",marginTop:"-30px" }}
        >
          <h5 className="poppinsBold Yellow-Color-Text" style={{}}>
            45k USDT
          </h5>
          <p
            className="poppinsRegular White-Color-Text"
            style={{ fontSize: "12px" }}
          >
            Daily Cashback
          </p>
        </Card>
      </Col>
      <Col md={4} xs={4}>
        <Card
          className="p-3 mb-5 shadow-lg"
          style={{ backgroundColor: "#16181C",marginTop:"-30px" }}
        >
          <h5 className="poppinsBold Yellow-Color-Text" style={{}}>
            1.35M USDT
          </h5>
          <p
            className="poppinsRegular White-Color-Text"
            style={{ fontSize: "12px" }}
          >
            Monthly Cashback
          </p>
        </Card>
      </Col>
      <Col md={12} xs={12}>
        <Card
          className="p-3 mb-5 shadow-lg"
          style={{ backgroundColor: "#16181C", height: "150px",marginTop:"-30px" }}
        >
          <h5 className="poppinsBold Yellow-Color-Text" style={{}}>
            Conversion
          </h5>
          <p
            className="poppinsRegular White-Color-Text"
            style={{ fontSize: "12px" }}
          >
            Daily cashback conversion into ALEIT.
          </p>
          <span className="poppinsBold Yellow-Color-Text" style={{}}>
            $0.08 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <FaArrowRight size={16} /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; $0.16 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <FaArrowRight size={16} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; $0.24 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <FaEllipsisH size={24} />
          </span>
          

        </Card>
      </Col>

      <Col md={12} xs={12}>
        <Card
          className="p-3 mb-5 shadow-lg"
          style={{ backgroundColor: "#16181C", height: "150px",marginTop:"-30px" }}
        >
          <h5 className="poppinsBold Yellow-Color-Text" style={{}}>
            ALEIT Up Trend
          </h5>
          <Row>
            <Col xs={6}>
              <p
                className="poppinsRegular White-Color-Text"
                style={{ fontSize: "12px" }}
              >
                Continous daily buying will increase aleit price.{" "}
              </p>
            </Col>
            <Col xs={6}>
              <img
                src={uptrend}
                className="img img-fluid"
                style={{ marginTop: "-80px" }}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default LiquidityChart;
