import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import 'react-multi-carousel/lib/styles.css';
import Slider from "react-slick";
import "react-vertical-timeline-component/style.min.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../../App.css";

function UpcomingExchanges() {
    const upcoming = [
        require('../../assets/images/Exchanges/bitmark.jpg'),
        require('../../assets/images/Exchanges/bitrue.jpg'),
        require('../../assets/images/Exchanges/coinstore.jpg'),
        require('../../assets/images/Exchanges/consbit.jpg'),
        require('../../assets/images/Exchanges/DEX.jpg'),
        require('../../assets/images/Exchanges/digifnex.jpg'),
        require('../../assets/images/Exchanges/lbank.png'),
        require('../../assets/images/Exchanges/P2B.jpg'),
        require('../../assets/images/Exchanges/poloniex.png'),
        require('../../assets/images/Exchanges/cointiger.jpg')
    ]
    const settings = {
        dots: false,
        arrows:false,
        infinite: true,
        speed: 1000,
        slidesToShow: 7,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        lazyLoad: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 7,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    }
    useEffect(() => {
        console.log("CALLED");
    }, []);
    return (
        <React.Fragment>
                <div className="p-5 mt-5 mb-5">
                    <h2 className="poppinsBold White-Color-Text mb-5" style={{ textAlign: "center" }} >UpComing Exchanges</h2>
                     <div className="slider-container" >
                       <Slider {...settings}>
                            {upcoming.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <div style={{margin:5}}>
                                        <Image src={item} thumbnail style={{ height: '120px', width: '500px', objectFit: 'contain' }} />
                                        </div>
                                    </div>
                                )
                            })}
                        </Slider>
                    </div> 
                </div>
        </React.Fragment>
    );
}
export default UpcomingExchanges;
