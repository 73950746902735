import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "react-vertical-timeline-component/style.min.css";
import "../../App.css";

import { Button, Col, Container, ProgressBar, Table } from "react-bootstrap";
import Chart from "react-google-charts";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";

// import { PieChart } from "react-minimal-pie-chart";

function TokenSale() {
  const tokensale = require("../../assets/images/tokensale2.png");
  
  return (
    <React.Fragment>
      <Container fluid id="tokenomics">
        <div className="row p-5">
          <div className="col-md-12 mb-5">
            <h1
              className="poppinsExtraBold White-Color-Text"
              style={{ textAlign: "center" }}
            >
              Token Sale
            </h1>
          </div>
          <div className="row">
            <Col xs={12} md={6}>
              <div className="row">
                <div
                  className="col-md-8"
                  style={{
                    border: "1px solid #313131",
                    paddingTop: "5px",
                    height: "30px",
                  }}
                >
                  <p
                    className="poppinsRegular White-Color-Text"
                    style={{ fontSize: "12px" }}
                  >
                    Token Name
                  </p>
                </div>
                <div
                  className="col-md-4"
                  style={{
                    border: "1px solid #313131",
                    paddingTop: "5px",
                    height: "30px",
                  }}
                >
                  <p
                    className="poppinsBold White-Color-Text"
                    style={{ fontSize: "12px" }}
                  >
                    ALL E IMRAN TOKEN
                  </p>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-8"
                  style={{
                    border: "1px solid #313131",
                    paddingTop: "5px",
                    height: "30px",
                  }}
                >
                  <p
                    className="poppinsRegular White-Color-Text"
                    style={{ fontSize: "12px" }}
                  >
                    Token Symbol
                  </p>
                </div>
                <div
                  className="col-md-4"
                  style={{
                    border: "1px solid #313131",
                    paddingTop: "5px",
                    height: "30px",
                  }}
                >
                  <p
                    className="poppinsBold White-Color-Text"
                    style={{ fontSize: "12px" }}
                  >
                    ALEIT
                  </p>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-8"
                  style={{
                    border: "1px solid #313131",
                    paddingTop: "5px",
                    height: "30px",
                  }}
                >
                  <p
                    className="poppinsRegular White-Color-Text"
                    style={{ fontSize: "12px" }}
                  >
                    Token Platform
                  </p>
                </div>
                <div
                  className="col-md-4"
                  style={{
                    border: "1px solid #313131",
                    paddingTop: "5px",
                    height: "30px",
                  }}
                >
                  <p
                    className="poppinsBold White-Color-Text"
                    style={{ fontSize: "12px" }}
                  >
                    BNB
                  </p>
                </div>
              </div>
              
              <div className="row">
                <div
                  className="col-md-8"
                  style={{
                    border: "1px solid #313131",
                    paddingTop: "5px",
                    height: "30px",
                  }}
                >
                  <p
                    className="poppinsRegular White-Color-Text"
                    style={{ fontSize: "12px" }}
                  >
                    Token Standard
                  </p>
                </div>
                <div
                  className="col-md-4"
                  style={{
                    border: "1px solid #313131",
                    paddingTop: "5px",
                    height: "30px",
                  }}
                >
                  <p
                    className="poppinsBold White-Color-Text"
                    style={{ fontSize: "12px" }}
                  >
                    BEP-20
                  </p>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-8"
                  style={{
                    border: "1px solid #313131",
                    paddingTop: "5px",
                    height: "30px",
                  }}
                >
                  <p
                    className="poppinsRegular White-Color-Text"
                    style={{ fontSize: "12px" }}
                  >
                    Max. Supply
                  </p>
                </div>
                <div
                  className="col-md-4"
                  style={{
                    border: "1px solid #313131",
                    paddingTop: "5px",
                    height: "30px",
                  }}
                >
                  <p
                    className="poppinsBold White-Color-Text"
                    style={{ fontSize: "12px" }}
                  >
                    1,00,00,00,000
                  </p>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-8"
                  style={{
                    border: "1px solid #313131",
                    paddingTop: "5px",
                    height: "30px",
                  }}
                >
                  <p
                    className="poppinsRegular White-Color-Text"
                    style={{ fontSize: "12px" }}
                  >
                    Available for Purchase
                  </p>
                </div>
                <div
                  className="col-md-4"
                  style={{
                    border: "1px solid #313131",
                    paddingTop: "5px",
                    height: "30px",
                  }}
                >
                  <p
                    className="poppinsBold White-Color-Text"
                    style={{ fontSize: "12px" }}
                  >
                    15,00,00,000
                  </p>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-12"
                  style={{
                    border: "1px solid #313131",
                    paddingTop: "5px",
                    height: "30px",
                  }}
                >
                  <p
                    className="poppinsExtraBold White-Color-Text"
                    style={{ fontSize: "12px", textAlign: "center" }}
                  >
                    TOKEN DISTRIBUTION
                  </p>
                </div>
                <div
                  className="col-md-8"
                  style={{
                    border: "1px solid #313131",
                    paddingTop: "5px",
                    height: "30px",
                  }}
                >
                  <p
                    className="poppinsRegular White-Color-Text"
                    style={{ fontSize: "12px" }}
                  >
                    Airdrop
                  </p>
                </div>
                <div
                  className="col-md-4"
                  style={{
                    border: "1px solid #313131",
                    paddingTop: "5px",
                    height: "30px",
                  }}
                >
                  <p
                    className="poppinsBold White-Color-Text"
                    style={{ fontSize: "12px" }}
                  >
                    5%
                  </p>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-8"
                  style={{
                    border: "1px solid #313131",
                    paddingTop: "5px",
                    height: "30px",
                  }}
                >
                  <p
                    className="poppinsRegular White-Color-Text"
                    style={{ fontSize: "12px" }}
                  >
                    Development
                  </p>
                </div>
                <div
                  className="col-md-4"
                  style={{
                    border: "1px solid #313131",
                    paddingTop: "5px",
                    height: "30px",
                  }}
                >
                  <p
                    className="poppinsBold White-Color-Text"
                    style={{ fontSize: "12px" }}
                  >
                    10%
                  </p>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-8"
                  style={{
                    border: "1px solid #313131",
                    paddingTop: "5px",
                    height: "30px",
                  }}
                >
                  <p
                    className="poppinsRegular White-Color-Text"
                    style={{ fontSize: "12px" }}
                  >
                    Pre Sale
                  </p>
                </div>
                <div
                  className="col-md-4"
                  style={{
                    border: "1px solid #313131",
                    paddingTop: "5px",
                    height: "30px",
                  }}
                >
                  <p
                    className="poppinsBold White-Color-Text"
                    style={{ fontSize: "12px" }}
                  >
                    15%
                  </p>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-8"
                  style={{
                    border: "1px solid #313131",
                    paddingTop: "5px",
                    height: "30px",
                  }}
                >
                  <p
                    className="poppinsRegular White-Color-Text"
                    style={{ fontSize: "12px" }}
                  >
                    Team Share & Reserves
                  </p>
                </div>
                <div
                  className="col-md-4"
                  style={{
                    border: "1px solid #313131",
                    paddingTop: "5px",
                    height: "30px",
                  }}
                >
                  <p
                    className="poppinsBold White-Color-Text"
                    style={{ fontSize: "12px" }}
                  >
                    15%
                  </p>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-8"
                  style={{
                    border: "1px solid #313131",
                    paddingTop: "5px",
                    height: "30px",
                  }}
                >
                  <p
                    className="poppinsRegular White-Color-Text"
                    style={{ fontSize: "12px" }}
                  >
                    Public Sale
                  </p>
                </div>
                <div
                  className="col-md-4"
                  style={{
                    border: "1px solid #313131",
                    paddingTop: "5px",
                    height: "30px",
                  }}
                >
                  <p
                    className="poppinsBold White-Color-Text"
                    style={{ fontSize: "12px" }}
                  >
                    55%
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={6}>
                <img src={tokensale} className="img img-fluid mt-3" />
            </Col>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default TokenSale;
