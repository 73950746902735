import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "react-vertical-timeline-component/style.min.css";
import "../App.css";

import { Nav, Navbar } from "react-bootstrap";

function AleitNavbar() {
  const logo = require("../assets/images/logo512.png");

  return (
    <React.Fragment>
      <Navbar
        // sticky="top"
        expand="lg"
        style={{ backgroundColor: "transparent" }}
      >
        <Navbar.Brand
          style={{
            color: "#FFFFFF",
            fontFamily: "poppinsBold",
            paddingLeft: "50px",
            fontSize:"40px"
          }}
          href="#home"
        >
          <img src={logo} style={{ height: "50px", width: "50px" }} /> AL-E-IT
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" style={{ paddingLeft: "30px" }}>
          <Nav className="me-auto"></Nav>
          <Nav className="me-auto"></Nav>
          <Nav className="me-auto"></Nav>
          <Nav className="me-auto">
            <Nav.Link
              style={{
                color: "#FFFFFF",
                fontFamily: "poppinsRegular",
                fontSize: "14px",
              }}
              href="#home"
            >
              Home
            </Nav.Link>
            <Nav.Link
              style={{
                color: "#FFFFFF",
                fontFamily: "poppinsRegular",
                fontSize: "14px",
              }}
              href="#mission"
            >
              Our Mission
            </Nav.Link>
            <Nav.Link
              style={{
                color: "#FFFFFF",
                fontFamily: "poppinsRegular",
                fontSize: "14px",
              }}
              href="#echo"
            >
              EchoSystem
            </Nav.Link>
            <Nav.Link
              style={{
                color: "#FFFFFF",
                fontFamily: "poppinsRegular",
                fontSize: "14px",
              }}
              href="#roadmap"
            >
              Roadmap
            </Nav.Link>
            <Nav.Link
              style={{
                color: "#FFFFFF",
                fontFamily: "poppinsRegular",
                fontSize: "14px",
              }}
              href="#tokenomics"
            >
              Tokenomics
            </Nav.Link>
            <Nav.Link
              style={{
                color: "#FFFFFF",
                fontFamily: "poppinsRegular",
                fontSize: "14px",
              }}
              href="#link"
            >
              FAQs
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </React.Fragment>
  );
}

export default AleitNavbar;
