import React from "react";
import MasterLayout from "../master/MasterLayout";
import { Col, Row } from "react-bootstrap";
import PresaleTimer from "../../../components/Presale/PresaleTimer";
import LiquidityChart from "../../../components/LiquidityLogic/LiquidityChart";

const Airdrop = () => {
  return (
    <MasterLayout>
      <Row className="mt-5">
        <Col md={6} sm={12} xs={12}>
        <h3 className="poppinsBold Yellow-Color-Text mb-5">Liquidity Enhancing Technique With Cashback</h3>
          <LiquidityChart />
        </Col>
        <Col md={6} sm={12} xs={12}>
          <PresaleTimer isWalletConnected={true} airdrop={true} presale={false} activeBtns={true} />
        </Col>
      </Row>
    </MasterLayout>
  );
};

export default Airdrop;
