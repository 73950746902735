import React from "react";
import MasterLayout from "../master/MasterLayout";

const Transactionhistory = () => {
  return (
    <MasterLayout>
      <div className="mt-5">
        <div>Transactionhistory SCREEN</div>
      </div>
    </MasterLayout>
  );
};

export default Transactionhistory;
