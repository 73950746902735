import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import "react-vertical-timeline-component/style.min.css";
import "../../../App.css";

import { Card } from "react-bootstrap";

function StageCard({ stage }) {
  useEffect(() => {
    console.log("Stage Card");
  }, []);
  return (
    <React.Fragment>
      {/* <div className="border border-5 " /> */}
      <Card
        className="text-center my-5 bg-dark"
        style={{ maxWidth: "22rem", backgroundColor: "#16181C" }}
      >
        <Card.Body>
          <Card.Header className="poppinsExtraBold mb-2  White-Color-Text border-secondary">
            STAGE {stage}
          </Card.Header>
          <Card.Title className="poppinsBold mb-3  White-Color-Text">
            0.000800$
          </Card.Title>
          <Card.Subtitle className="poppinsBold mb-3 Yellow-Color-Text ">
            240,000$
          </Card.Subtitle>
          <Card.Text className="poppinsRegular White-Color-Text  ">
            Maximum potential growth and early staking rewards.
          </Card.Text>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}

export default StageCard;
