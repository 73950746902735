import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import "react-vertical-timeline-component/style.min.css";
import "../../App.css";

import { Button, Container, ProgressBar } from "react-bootstrap";

function MissionStatement() {
  const missionImage = require("../../assets/images/mission.png");
  useEffect(() => {
    console.log("CALLED");
    
  }, []);
  return (
    <React.Fragment>
      <Container id="mission">
        <div className="row p-5">
          <div className="col-md-5 col-xs-12">
            <img src={missionImage} className="img img-fluid" />
          </div>
          <div className="col-md-1 col-xs-12"></div>
          <div className="col-md-6 col-xs-12 mt-5">
            <p
              className="poppinsBold White-Color-Text"
              // style={{ textAlign: "center" }}
            >
              MISSION
            </p>
            <h1
              className="poppinsExtraBold White-Color-Text"
              // style={{ textAlign: "center" }}
            >
              Our AL-E-IT Mission
            </h1>
            <p
              className="poppinsRegular White-Color-Text"
              style={{ fontSize: "12px" }}
            >
              Our mission is to revolutionize the customer experience by
              providing a seamless platform that rewards users with crypto
              tokens for every purchase. We are dedicated to empowering
              customers with financial benefits, ensuring they earn a handsome
              amount at the end of each month through our innovative cashback
              system. By leveraging the power of ALL E IMRAN, we aim to create a
              community where every transaction becomes an opportunity for
              customers to not only enjoy their purchases but also reap
              significant cashback rewards. Our commitment lies in redefining
              the way people shop, offering them a pathway to financial
              prosperity through the exciting world of CashBackIt.
            </p>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default MissionStatement;
