import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import {
  Container
} from "react-bootstrap";
import Slider from "react-slick";
import "react-vertical-timeline-component/style.min.css";
import "../../App.css";
import StageCard from "../../examples/Cards/StageCards/StageCard";

function Stages() {
  const settings = {
    dots: true,
    arrows:true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
   
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
            }
        }
    ]
}
  return (
    <React.Fragment>
      <Container className="my-3 py-5">
        <h1
          className="poppinsBold White-Color-Text "
          style={{ textAlign: "center" }}
        >
          Presale
        </h1>
        <p
          className="poppinsRegular White-Color-Text  "
          style={{ textAlign: "center" }}
        >
          Join the ALEIT Presale Journey – Your Path to Early Rewards
        </p>
      
        <Slider  {...settings} >        
          <StageCard stage={1}  />
          <StageCard stage={2}  />
          <StageCard stage={3}  />
          <StageCard stage={4}  />
        </Slider>
      
      </Container>
    </React.Fragment>
  );
}

export default Stages;
